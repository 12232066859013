import { checkCan } from 'casl/check';
import React, { Suspense } from 'react';
import { Navigate } from 'react-router-dom';
import { useAppSelector } from 'redux/hooks';
import { AcessAction, AcessItem } from 'types/enum/items';

type CheckPermissionProps = {
    component: React.LazyExoticComponent<() => JSX.Element>;
    action: AcessAction;
    on: AcessItem;
};
const loading = () => <div className=""></div>;

export const CheckPermission = ({ component: Component, action, on }: CheckPermissionProps) => {
    const { user } = useAppSelector((state) => state.Auth);
    return user === undefined ? (
        loading()
    ) : checkCan(user, action, on) ? (
        <Suspense fallback={loading()}>
            <Component />
        </Suspense>
    ) : (
        <Navigate to={'/'} />
    );
};
