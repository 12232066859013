export enum AcessItem {
    USUARIO = 'Usuario',
    SIMULATION = 'Simulation',
    SUPERUSUARIO = 'SuperUsuario',
}

export enum AcessAction {
    UPDATE = 'update',
    DELETE = 'delete',
    CREATE = 'create',
    READ = 'read',
}
