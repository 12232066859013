import { createMongoAbility, AbilityBuilder } from '@casl/ability';
import { UsuarioF } from 'types';
import { AcessAction, AcessItem } from 'types/enum/items';

const defineAbility = (user: UsuarioF | null) => {
    const { can, cannot, build } = new AbilityBuilder(createMongoAbility);
    if (user?.is_superuser) {
        can('manage', 'all');
    } else if (user?.is_staff) {
        can('manage', AcessItem.USUARIO);
        can('manage', AcessItem.SIMULATION);
    } else {
        can(AcessAction.READ, AcessItem.USUARIO);
        can(AcessAction.READ, AcessItem.SIMULATION);
    }

    return build();
};

export default defineAbility;
