import { Navigate, useLocation, useSearchParams } from 'react-router-dom';
import { useProfileQuery } from 'redux/features/api/api-slice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { setProfile } from 'redux/features/auth/auth-slice';
import { useEffect } from 'react';

type PrivateRouteProps = {
    component: React.ComponentType;
    roles?: string;
};

/**
 * Private Route forces the authorization before the route can be accessed
 * @param {*} param0
 * @returns
 */
const PrivateRoute = ({ component: RouteComponent, roles, ...rest }: PrivateRouteProps) => {
    let location = useLocation();
    const [searchParams] = useSearchParams();
    // const [loggedInUser] = useUser();
    const { userLogged } = useAppSelector((state) => state.Auth);
    const dispatch = useAppDispatch();
    const { data, isSuccess } = useProfileQuery(undefined, { skip: !userLogged });
    // const api = new APICore();
    useEffect(() => {
        if (isSuccess && data !== undefined) dispatch(setProfile(data));
    }, [isSuccess]);
    /**
     * not logged in so redirect to login page with the return url
     */
    if (!userLogged) {
        return (
            <Navigate
                to={'/account/login'}
                state={{ from: location, ...Object.fromEntries(searchParams.entries()) }}
                replace
            />
        );
    }
    // // check if route is restricted by role
    // if (roles && roles.indexOf(loggedInUser.role) === -1) {
    //     // role not authorised so redirect to home page
    //     return <Navigate to={{ pathname: '/' }} />;
    // }
    return <RouteComponent />;
};

export default PrivateRoute;
