const config = {
    PORT: process.env.PORT,
    REACT_APP_API_URL: process.env.REACT_APP_API_URL,
    Cookie: {
        ACCESS_PREFIX: process.env.REACT_APP_ACCESS_PREFIX as string,
        REFRESH_PREFIX: process.env.REACT_APP_REFRESH_PREFIX as string,
    },
};

export default config;
