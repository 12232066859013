import { createSlice } from '@reduxjs/toolkit';

interface DeleteState {
    show: boolean;
}

const initialState: DeleteState = {
    show: false,
};

const deleteSlice = createSlice({
    name: 'deleteSlice',
    initialState,
    reducers: {
        toggle(state) {
            state.show = !state.show;
        },
    },
});

export const { toggle } = deleteSlice.actions;
export default deleteSlice.reducer;
