import Alerts from './features/alertas/alertas-slice';
import { serviceApi } from './features/api/api-slice';
import Layout from './features/layouts/layout-slice';
import Delete from './features/delete/delete-slice';
import Select from './features/select/select-slice';
import Auth from './features/auth/auth-slice';
import { combineReducers } from 'redux';

export default combineReducers({
    [serviceApi.reducerPath]: serviceApi.reducer,
    Alerts,
    Delete,
    Layout,
    Select,
    Auth,
});
