import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { BarragemF } from 'types';

interface SelectState {
    selected?: BarragemF['id'];
}

const initialState: SelectState = {};

const selectSlice = createSlice({
    name: 'selectSlice',
    initialState,

    reducers: {
        setSelected(state, { payload }: PayloadAction<BarragemF['id']>) {
            state.selected = payload;
        },
    },
});

export const { setSelected } = selectSlice.actions;
export default selectSlice.reducer;
