import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import config from 'config';
import Cookies from 'js-cookie';
import { UsuarioF } from 'types';

interface AuthState {
    access?: string;
    refresh?: string;
    userLogged: boolean;
    user?: UsuarioF;
}

interface AuthPayload {
    access: string;
    refresh: string;
}
const getInitialState = (): AuthState => {
    const access = Cookies.get(config.Cookie.ACCESS_PREFIX);
    const refresh = Cookies.get(config.Cookie.REFRESH_PREFIX);
    return {
        access,
        refresh,
        userLogged: access !== undefined && refresh !== undefined,
    };
};
const initialState: AuthState = getInitialState();

const authSlice = createSlice({
    name: 'AuthSlice',
    initialState,
    reducers: {
        loggedOut(state) {
            state.access = undefined;
            state.refresh = undefined;
            state.userLogged = false;
            state.user = undefined;
            Cookies.remove(config.Cookie.ACCESS_PREFIX);
            Cookies.remove(config.Cookie.REFRESH_PREFIX);
        },
        setUserLogged(state, { payload: { access, refresh } }: PayloadAction<AuthPayload>) {
            state.access = access;
            state.refresh = refresh;
            state.userLogged = true;
            Cookies.set(config.Cookie.ACCESS_PREFIX, access, { expires: 7, secure: true });
            Cookies.set(config.Cookie.REFRESH_PREFIX, refresh, { expires: 7, secure: true });
        },
        setProfile(state, { payload }: PayloadAction<UsuarioF>) {
            state.user = payload;
        },
    },
});

export const { setUserLogged, loggedOut, setProfile } = authSlice.actions;
export default authSlice.reducer;
